import { useEffect, useState } from 'react';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Split, Treatment } from 'src/constants/Split';

import { useAuthContext } from 'src/components/AuthProvider';
import { isReady } from 'src/components/SplitContext/isReady';

export const useAutoLogin = () => {
  const { isAuthenticated, loading, refresh } = useAuthContext();
  const [hasCheckedUser, setHasCheckedUser] = useState(false);
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureHdcMfa]);
  const hasMfa = treatments[Split.FeatureHdcMfa].treatment === Treatment.On;

  useEffect(() => {
    if (!isAuthenticated && !loading && !hasCheckedUser && splitIsReady) {
      refresh(hasMfa ? { acr_values: 'aal1' } : {});
      setHasCheckedUser(true);
    }
  }, [loading, isAuthenticated, splitIsReady]);
};
