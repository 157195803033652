import { useEffect } from 'react';
import { navigate } from 'gatsby';

import { isAgent } from 'src/api/client';

export const usePreventAgentAccess = () => {
  useEffect(() => {
    if (isAgent) {
      navigate('/agent-unauthorized');
    }
  }, []);
};
