import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { useTreatments } from '@splitsoftware/splitio-react';

import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import useJoinTheClubLink from 'src/hooks/useJoinTheClubLink';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { Translate } from 'src/components/Translate';
import LoadingContainer from 'src/components/LoadingContainer';
import { Split, Treatment } from 'src/constants/Split';

import 'src/components/JoinCta/styles.scss';
import useBenefitsPageQueries from '../benefits/useBenefitsPageQueries';

type Props = {
  className?: string;
  disclaimerClassName?: string;
  testid?: string;
  disclaimerTestId?: string;
  hideDisclaimer?: boolean;
  onClick?: () => void;
};

const JoinCta: React.FC<Props> = ({
  className,
  disclaimerClassName,
  testid,
  disclaimerTestId,
  onClick,
  hideDisclaimer = false,
}) => {
  const { ready, membership } = useBenefitsPageQueries();
  const { isStfrmCustomer, hasStfCustomerStandalonePurchase } = useShelbyCustomer();
  const linkTo = useJoinTheClubLink();
  const treatments = useTreatments([Split.ExperimentMonthlyPricing, Split.FeatureBenefitsPage]);
  const hasMonthlyPriceExperiment = treatments[Split.ExperimentMonthlyPricing].treatment === Treatment.On;
  const hasBenefitsPage = treatments[Split.FeatureBenefitsPage].treatment === Treatment.On;
  // Use isEligibleForFreeTrial to check if customer has previously had free trial as well
  const { isEligibleForFreeTrial, freeTrialIsReady } = useFreeTrial();

  const joinPrice = hasMonthlyPriceExperiment ? 'monthlyPriceOn.price' : 'cta.join.singleTier';
  const cta = isEligibleForFreeTrial ? 'free-trial.cta.start' : joinPrice;
  const disclaimer = isEligibleForFreeTrial ? 'free-trial.cta.disclaimer' : 'cta.monthly.price.disclaimer';
  const hasDisclaimer = !hideDisclaimer && (isEligibleForFreeTrial || hasMonthlyPriceExperiment);

  if ((isStfrmCustomer && !hasStfCustomerStandalonePurchase) || (ready && membership && hasBenefitsPage)) return null;

  return (
    <>
      <Link
        to={linkTo}
        className={cx('button', className || 'button_primary button_primary_large')}
        data-cy={testid || 'join-cta'}
        onClick={onClick}
        aria-labelledby={testid}
      >
        {freeTrialIsReady ? <Translate id={testid} resourceKey={cta} /> : <LoadingContainer />}
      </Link>
      {hasDisclaimer && (
        <Translate
          as="p"
          className={cx('pt-4 lg-plus-pt-6 no-margin font-size-x-small join-cta-disclaimer', disclaimerClassName)}
          data-cy={disclaimerTestId || 'free-trial-disclaimer'}
          resourceKey={disclaimer}
          useHtml
        />
      )}
    </>
  );
};

export { JoinCta };
